<template>
    <div class="Master">
        <UserCard :user="myTeacher.master" v-if="myTeacher.master"></UserCard>
        <!-- 未拜师 -->
        <div class="master-tip" v-if="myTeacher.examine != 1">
            <div class="flex-alc column tip">
                <img
                    src="@/assets/nodata_1@2x.png"
                    class="noMasterImg"
                    alt=""
                />
                <div class="tipText">您还没师傅呢</div>
                <div class="learn click" @click="goTeacher">去拜师</div>
            </div>
        </div>

        <div class="master-contai" v-if="myTeacher.examine == 1">
            <div class="master-left">
                <div class="flex-alc">
                    <div
                        class="flex-alc column mr-62 click"
                        @click="switchTag(0)"
                    >
                        <div class="tag" :class="{ 'sel-tag': active == 0 }">
                            辅导计划
                        </div>
                        <div
                            class="line"
                            :class="{ 'sel-line': active == 0 }"
                        ></div>
                    </div>
                    <div class="flex-alc column click" @click="switchTag(1)">
                        <div class="tag" :class="{ 'sel-tag': active == 1 }">
                            师徒日志
                        </div>
                        <div
                            class="line"
                            :class="{ 'sel-line': active == 1 }"
                        ></div>
                    </div>
                </div>
                <div class="task" v-show="active == 0">
                    <NoData
                        v-if="!coachList.length"
                        text="你的师傅还没有给你安排计划哦~"
                    ></NoData>
                    <div
                        class="plan mb-27"
                        v-for="(item, index) in coachList"
                        :key="'plan' + index"
                    >
                        <div class="time">
                            {{
                                item.fmt_created
                                    .replace("-", "年")
                                    .replace("-", "月") + "日"
                            }}
                        </div>
                        <div
                            class="detail click"
                            @click="
                                !item.category
                                    ? goCur(item.curriculum_info.id)
                                    : goTask(item)
                            "
                        >
                            <div class="flex-jsb">
                                <div class="flex-alc">
                                    <div
                                        class="type"
                                        :class="{ 'type-up': !item.category }"
                                    >
                                        {{ item.category ? "线下" : "线上" }}
                                    </div>
                                    <div class="desc one-line">
                                        师傅安排学习《{{ item.fmt_title }}》
                                    </div>
                                </div>
                                <div class="flex-alc">
                                    <div class="jt">>></div>
                                </div>
                            </div>
                            <div class="mt-10 flex-jsb flex-ale">
                                <div class="flex-ale" v-show="!item.category">
                                    <img
                                        :src="item.curriculum_info.cover"
                                        alt=""
                                        class="cur-img mr-11"
                                    />
                                    <div class="flex-alc">
                                        <div class="fabulous mr-13">
                                            <img
                                                src="@/assets/dz.png"
                                                alt=""
                                                class="icon"
                                            />
                                            <div class="ml-5">
                                                {{
                                                    item.curriculum_info
                                                        .like_number
                                                }}
                                            </div>
                                        </div>
                                        <div class="fabulous">
                                            <img
                                                src="@/assets/pl.png"
                                                alt=""
                                                class="icon"
                                            />
                                            <div class="ml-5">
                                                {{
                                                    item.curriculum_info
                                                        .estimate_number
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-show="item.category"
                                    class="brief one-line"
                                >
                                    {{ item.desc }}
                                </div>
                                <div
                                    class="surplus"
                                    :class="{ complete: item.status }"
                                >
                                    {{
                                        item.status
                                            ? "已完成"
                                            : `剩余${item.fmt_days}天`
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="record" v-show="active == 1">
                    <NoData
                        v-if="!recordList.length"
                        text="目前没有日志信息~"
                    ></NoData>
                    <div
                        class="mb-27"
                        v-for="(item, index) in recordList"
                        :key="'record' + index"
                    >
                        <div class="time">
                            {{ item.fmt_created.replace("-", "月") + "日" }}
                        </div>
                        <div
                            class="desc"
                            :class="{ type: item.status }"
                            v-if="!item.message"
                        >
                            {{ item.status ? "线下" : "线上" }}辅导计划：<span
                                class="identity"
                                >{{ item.identity ? "徒弟" : "师傅" }}</span
                            >{{ item.identity ? "完成了" : "安排学习" }}《{{
                                item.content
                            }}》
                        </div>
                        <div class="desc" v-if="item.message">
                            {{ item.content }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="master-right">
                <div class="bottom">
                    <div
                        class="wh-tip"
                        :style="{ display: showTipWh ? 'flex' : 'none' }"
                    >
                        您需要完成个人导师给您布置的专项任务方可获得成长值喔~
                    </div>
                    <div class="flex-alc between mb-20">
                        <div
                            class="flex-alc column click"
                            @click="selRanking(0)"
                        >
                            <div
                                class="rankingText"
                                :style="{
                                    color: ranking == 0 ? '#000' : '#B7B7B7',
                                }"
                                @mouseover="showTipWh = true"
                                @mouseout="showTipWh = false"
                            >
                                我的成长值
                            </div>
                        </div>
                        <div
                            class="flex-alc column click"
                            @click="selRanking(1)"
                        >
                            <div
                                class="rankingText"
                                :style="{
                                    color: ranking == 1 ? '#000' : '#B7B7B7',
                                }"
                            >
                                成长值记录
                            </div>
                        </div>
                    </div>

                    <div class="flex-alc column mt-20" v-show="ranking == 0">
                        <div class="re">
                            <img
                                src="@/assets/image_czz@2x.png"
                                class="ball"
                                alt=""
                            />
                            <div class="growth">{{ my_growth }}</div>
                        </div>
                        <div class="month mt-10 mb-15">
                            {{ `以下是${month}月榜单` }}
                            <!-- <img
                                @mouseover="showTipWh = true"
                                @mouseout="showTipWh = false"
                                src="@/assets/ic_wh@2x.png"
                                class="wh-img click"
                            /> -->
                        </div>
                    </div>

                    <div
                        class="flex-alc between pl-35 pr-35 mt-13 mb-36"
                        v-show="ranking == 0"
                    >
                        <div class="flex-alc column click" @click="selType(0)">
                            <div class="list">月榜</div>
                            <div
                                class="selList"
                                :class="{ opt0: typeRanking != 0 }"
                            ></div>
                        </div>
                        <div class="flex-alc column click" @click="selType(1)">
                            <div class="list">总榜</div>
                            <div
                                class="selList"
                                :class="{ opt0: typeRanking != 1 }"
                            ></div>
                        </div>
                    </div>

                    <div class="pl-13 pr-13">
                        <!-- 成长值 -->
                        <div
                            class="flex-alc between mb-23"
                            v-for="(item, index) in rankingList"
                            :key="'user' + index"
                            v-show="index < 3 && ranking == 0"
                        >
                            <div class="flex-alc">
                                <img
                                    :src="ImgList[index]"
                                    class="rankingImg mr-15"
                                />
                                <div class="text">{{ item.name }}</div>
                            </div>
                            <div class="text">
                                {{ ranking ? item.credit : item.amount }}
                            </div>
                        </div>
                        <!-- 记录 -->
                        <NoData
                            v-if="!growthRecord.length && ranking == 1"
                            text="目前还没有成长记录~"
                            :img="false"
                            :gray="true"
                        ></NoData>
                        <div
                            v-show="ranking == 1"
                            class="growth-record"
                            v-for="(item, index) in growthRecord"
                            :key="'gro' + index"
                        >
                            <div class="flex-alc between">
                                <div class="time">{{ item.fmt_created }}</div>
                                <div class="rate">+{{ item.score }}</div>
                            </div>
                            <div class="end">完成《{{ item.content }}》</div>
                        </div>
                    </div>

                    <div class="lookRanking click" @click="lookRanking">
                        查看全榜 >
                    </div>
                </div>
            </div>
        </div>

        <LearnTeacherSuccess
            :relationship="true"
            v-if="myTeacher.notice"
        ></LearnTeacherSuccess>
        <Evaluate
            v-if="isEvaluate"
            :text="isEvaluate"
            :id="myTeacher.relate"
            :name="myTeacher.name"
            @confirm="confirm"
        ></Evaluate>
        <EvaluateSuccess
            v-if="isEvaluateSuccess"
            text="评价成功"
        ></EvaluateSuccess>

        <!-- 线下任务完成 -->
        <el-dialog
            :visible.sync="showTask"
            :show-close="true"
            class="fill-task"
            width="648px"
        >
            <div class="title">线下任务完成填写</div>
            <el-divider></el-divider>
            <div class="wid_100 pr-40 pl-40 pb-40">
                <div class="task-title">辅导计划标题</div>
                <div class="task-success">完成《{{ offTask.fmt_title }}》</div>
                <div class="task-title">辅导计划简介</div>
                <div class="task-success">{{ offTask.desc }}</div>
                <div class="task-title">完成说明</div>
                <el-input
                    type="textarea"
                    placeholder="有什么想说的"
                    v-model="explain"
                    maxlength="200"
                    class="mb-18"
                    :rows="5"
                    show-word-limit
                >
                </el-input>
                <div class="task-title">
                    添加图片<span class="num">（{{ images.length }}/6）</span>
                </div>
                <el-upload
                    :action="ossToken.host"
                    list-type="picture-card"
                    :on-remove="handleRemove"
                    :on-success="uploadSuccess"
                    :data="uploadData"
                    :before-upload="beforUpload"
                    class="pb-45 flex wrap"
                    :class="{
                        noUploas: images.length > 5,
                        yesUploas: images.length < 6,
                    }"
                    :limit="6"
                >
                    <i class="el-icon-plus" v-show="images.length < 7"></i>
                </el-upload>
                <div class="subTask click" @click="subTask">提交任务</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import NoData from "@/components/NoData.vue";
    import UserCard from "@/components/UserCard.vue";
    import Evaluate from "@/components/Evaluate.vue";
    import LearnTeacherSuccess from "@/components/LearnTeacherSuccess.vue";
    import EvaluateSuccess from "@/components/EvaluateSuccess.vue";
    export default {
        name: "Master",
        components: {
            UserCard,
            LearnTeacherSuccess,
            Evaluate,
            EvaluateSuccess,
            NoData,
        },
        data() {
            return {
                active: 0, //0 辅导计划 1 师徒日志
                myTeacher: {}, //师傅详情
                coachList: [], //辅导计划列表
                recordList: [], //师徒日志
                ranking: 0, //0 成长值 1 成长记录
                typeRanking: 0, //0 月榜  1 总榜
                month: 0, //月份
                my_growth: 0, //我的成长值
                rankingList: [], //排行列表
                ImgList: [
                    require("@/assets/jp.png"),
                    require("@/assets/yp.png"),
                    require("@/assets/tp.png"),
                ], //金银铜排行图
                growthRecord: [], //记录列表
                isEvaluate: "", //是否弹出评论
                isEvaluateSuccess: false, //是否评价成功
                showTask: false, //完成任务提交弹窗
                offTask: {},

                explain: "", //任务完成说明
                images: [],
                fileName: "", //上传的文件名
                showTipWh: false, //显示成长值获取提示
            };
        },

        computed: {
            ossToken: function () {
                return this.$store.state.ossToken;
            },
            uploadData: function () {
                return {
                    key: "",
                    policy: this.ossToken.policy,
                    OSSAccessKeyId: this.ossToken.accessid,
                    signature: this.ossToken.signature,
                };
            },

            user: function () {
                return this.$store.state.user;
            },
        },

        async mounted() {
            window.localStorage.setItem("myNavActive", 2);
            this.$store.commit("switchMyNav", 2);
            //评价师傅
            await this.$https.get("/api/my_master/evaluate").then((res) => {
                if (res.errcode) return;
                this.isEvaluate = `${res.data.this_month}/${
                    res.data.this_day < 10
                        ? "0" + res.data.this_day
                        : res.data.this_day
                }`;
            });
            //师傅详情
            await this.$https.get("/api/my_master/detail").then((res) => {
                if (res.errcode || res.data.examine != 1) return;
                this.myTeacher = res.data;
            });
            this.getCoach();
            this.getRecord();
            this.getRanking();
        },

        methods: {
            //上传文件前处理
            beforUpload(file) {
                this.uploadData.key =
                    (this.ossToken.dir ? this.ossToken.dir + "/" : "") + file.name;
                this.fileName = file.name;
            },
            //提交任务
            subTask() {
                let images = "",
                    list = [];
                this.images.forEach((i) => {
                    list.push(i.url);
                });
                images = list.join();
                this.$https
                    .post("/api/my_master/tesks", {
                        explain: this.explain,
                        images,
                        id: this.offTask.id,
                    })
                    .then((res) => {
                        if (res.errcode) return this.$message.error(res.msg);
                        this.getCoach();
                        this.getRecord();
                        this.getRanking();
                        this.showTask = false;
                    });
            },
            //打开任务弹窗
            goTask(e) {
                this.offTask = e;
                this.showTask = true;
            },
            //上传成功
            uploadSuccess(e) {
                console.log(e);
                this.images.push({
                    url: `${this.ossToken.host}/${this.uploadData.key}`,
                    hash: this.fileName,
                });
            },
            //删除图片
            handleRemove(file, fileList) {
                let ruleForm = this.images;
                for (let i = 0; i < ruleForm.length; i++) {
                    if (!file.response) {
                        if (ruleForm[i].hash == file.name) {
                            this.images.splice(i, 1);
                            break;
                        }
                    } else {
                        if (ruleForm[i].hash == file.name) {
                            this.images.splice(i, 1);
                            break;
                        }
                    }
                }
            },
            //提交评价师傅
            confirm(e) {
                this.$https
                    .post(`/api/my_master/evaluate`, {
                        id: this.myTeacher.relate,
                        score: e.score,
                        content: e.content,
                    })
                    .then((res) => {
                        if (res.errcode) return this.$message.error(res.msg);
                        this.isEvaluateSuccess = true;
                        this.isEvaluate = "";
                    });
            },

            //课程详情
            goCur(e) {
                this.$router.push({
                    path: "/CurriculumDetail",
                    query: { id: e },
                });
            },
            //获取排行榜
            getRanking() {
                if (this.ranking == 0) {
                    this.$https
                        .get("/api/my_master/growth_rank", {
                            kind: this.typeRanking ? "total" : "month",
                            id: this.user.id || 0,
                        })
                        .then((res) => {
                            this.rankingList = res.data.list;
                            this.my_growth = res.data.my_growth;
                            this.month = res.data.month;
                        });
                } else {
                    this.$https
                        .get("/api/my_master/growth_list", {
                            page: 1,
                            limit: 7,
                            id: this.user.id || 0,
                        })
                        .then((res) => {
                            this.growthRecord = res.data.list;
                        });
                }
            },
            //查看全榜
            lookRanking() {
                this.$router.push({ path: "/myInfo/growth" });
            },
            //切换月、总榜
            selType(e) {
                this.typeRanking = e;
                this.getRanking();
            },
            //切换成长值和记录
            selRanking(e) {
                this.ranking = e;
                this.getRanking();
            },
            //去拜师
            goTeacher() {
                this.$router.push({ path: "/myInfo/LearnTeacher" });
            },
            //切换tag
            switchTag(e) {
                this.active = e;
            },
            //获取辅导计划列表
            getCoach() {
                this.$https
                    .get("/api/my_master/coach", { page: 1, limit: 999 })
                    .then((res) => {
                        this.coachList = res.data.list;
                    });
            },
            //获取师徒日志
            getRecord() {
                this.$https
                    .post("/api/master_pupil/record", {
                        page: 1,
                        limit: 999,
                        relate: this.myTeacher.relate,
                    })
                    .then((res) => {
                        this.recordList = res.data.list;
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
.Master {
    width: 100%;
    .master-tip {
        background: white;
        border-radius: 7px;
        margin-top: 20px;
        .noMasterImg {
            width: 134px;
            height: 112px;
            margin-top: 66px;
        }
        .tipText {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            margin-top: 31px;
            margin-bottom: 21px;
        }
        .learn {
            width: 100px;
            height: 32px;
            background: linear-gradient(180deg, #ff3764, #ff2604);
            border-radius: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 140px;
        }
    }
    .master-contai {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .master-left {
            width: 633px;
            background: white;
            padding: 18px 30px;
            height: max-content;
            .tag {
                font-size: 14px;
                font-weight: 400;
                color: #a1a1a1;
            }
            .sel-tag {
                font-size: 16px;
                font-weight: 600;
                color: #e41414;
            }
            .line {
                width: 43px;
                height: 4px;
                border-radius: 4px;
                margin-top: 9px;
            }
            .sel-line {
                background: linear-gradient(180deg, #ff3764, #ff2604);
            }
            .task::-webkit-scrollbar {
                opacity: 0;
            }
            .record::-webkit-scrollbar {
                opacity: 0;
            }
            .task {
                padding: 25px 0px;
                height: 450px;
                overflow-y: scroll;
                .plan {
                    .time {
                        font-size: 14px;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: 10px;
                    }
                    .detail {
                        width: 100%;
                        padding: 11px 8px;
                        background: #f6f6f6;
                        border-radius: 5px;
                        .type {
                            border: 1px solid #21ad29;
                            padding: 1px 8px;
                            border-radius: 7px 7px 7px 0px;
                            margin-right: 12px;
                            font-size: 12px;
                            font-weight: 400;
                            color: #21ad29;
                            min-width: 45px;
                        }
                        .desc {
                            font-size: 14px;
                            font-weight: 400;
                            color: #000000;
                        }
                        .type-up {
                            color: #f32929;
                            border: 1px solid #f32929;
                        }
                        .surplus {
                            background: #fcdddd;
                            padding: 1px 8px;
                            font-size: 12px;
                            font-weight: 400;
                            color: #f32929;
                            border-radius: 10px;
                            margin-left: 10px;
                            height: max-content;
                        }
                        .complete {
                            color: #21ad29;
                            background: #e6ffe7;
                        }
                        .jt {
                            font-size: 14px;
                            font-weight: 400;
                            color: #000000;
                            margin-left: 10px;
                        }
                        .brief {
                            font-size: 14px;
                            font-weight: 400;
                            color: #afafaf;
                            margin-left: 52px;
                            width: 380px;
                            -webkit-line-clamp: 5;
                        }
                        .cur-img {
                            width: 218px;
                            height: 116px;
                        }
                        .fabulous {
                            font-size: 11px;
                            font-weight: 400;
                            color: #797979;
                            display: flex;
                            align-items: center;

                            .icon {
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                }
            }
            .record {
                padding: 25px 0;
                height: 450px;
                overflow-y: scroll;
                .time {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000000;
                    margin-bottom: 5px;
                }
                .desc {
                    font-size: 14px;
                    font-weight: 400;
                    color: #000000;
                    margin-top: 6px;
                    .identity {
                        color: red;
                    }
                }
            }
        }
        .master-right {
            .bottom {
                width: 318px;
                padding: 18px 22px;
                background: #ffffff;
                border-radius: 4px 4px 15px 15px;
                position: relative;

                .wh-tip {
                    position: absolute;
                    top: -80px;
                    padding: 0 10px;
                    left: 21px;
                    width: 273px;
                    height: 69px;
                    background: #ffffff;
                    box-shadow: 0px 2px 9px 0px #c2c2c2;
                    border-radius: 5px;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #fe3247;
                    display: none;
                    align-items: center;
                    justify-content: center;
                    z-index: 99;
                }

                .rankingText {
                    font-size: 16px;
                    font-weight: 500;
                }
                .ranking {
                    width: 43px;
                    height: 4px;
                    background: linear-gradient(180deg, #ff3764, #ff2604);
                    border-radius: 4px;
                }

                .ball {
                    width: 111px;
                    height: 111px;
                }
                .number {
                    font-size: 40px;
                    font-weight: 500;
                    color: #e41414;
                }
                .my-rate {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000000;
                }
                .month {
                    font-size: 16px;
                    font-weight: 400;
                    color: #000000;
                    display: flex;
                    align-items: center;
                    .wh-img {
                        width: 20px;
                        height: 20px;
                        margin-left: 2px;
                    }
                }
                .growth {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 40px;
                    font-weight: 500;
                    color: #ffffff;
                }

                .list {
                    font-size: 18px;
                    font-weight: 500;
                    color: #000000;
                }

                .selList {
                    width: 44px;
                    height: 3px;
                    background: linear-gradient(180deg, #ff3764, #ff2604);
                    border-radius: 4px;
                    margin-top: 5px;
                }

                .text {
                    font-size: 16px;
                    font-weight: 500;
                    color: #000000;
                }
                .rankingImg {
                    width: 23px;
                    height: 29px;
                }
                .lookRanking {
                    font-size: 14px;
                    font-weight: 400;
                    color: #929292;
                    text-align: center;
                }
                .growth-record {
                    margin-bottom: 22px;
                    .time {
                        font-size: 17px;
                        font-weight: 500;
                        color: #000;
                    }
                    .rate {
                        font-size: 20px;
                        font-weight: 500;
                        color: #fe3040;
                    }
                    .end {
                        font-size: 17px;
                        font-weight: 400;
                        color: #000000;
                    }
                }
            }
        }
    }
    .fill-task {
        /deep/.el-dialog {
            border-radius: 10px;
            .el-dialog__header {
                display: none;
            }
            .el-dialog__body {
                padding: 0;
                .title {
                    font-size: 18px;
                    font-weight: 500;
                    color: #000000;
                    padding: 19px 0;
                    text-align: center;
                }
                .el-divider--horizontal {
                    margin-top: 0;
                    margin-bottom: 19px;
                    height: 2px;
                }
                .task-title {
                    font-size: 14px;
                    font-weight: 500;
                    color: #2d2d2d;
                    margin-bottom: 7px;
                    .num {
                        font-size: 12px;
                        font-weight: 400;
                        color: #b2b2b2;
                    }
                }
                .yesUploas .el-upload--picture-card {
                    width: 102px;
                    height: 102px;
                    background: #f6f6f6;
                    border: 1px solid #d5d5d5;
                }
                .el-upload--picture-card i {
                    height: 102px;
                    width: 102px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .noUploas .el-upload--picture-card {
                    display: none;
                }
                .el-upload-list--picture-card .el-upload-list__item {
                    width: 102px;
                    height: 102px;
                }
                .task-success {
                    font-size: 14px;
                    font-weight: 400;
                    color: #858585;
                    margin-bottom: 25px;
                }
                .el-textarea .el-textarea__inner {
                    background: #f6f6f6;
                    border: 1px solid #d5d5d5;
                }
                .el-textarea .el-input__count {
                    background: #f6f6f6;
                }
                .subTask {
                    width: 130px;
                    height: 32px;
                    background: linear-gradient(180deg, #ff3764, #ff2604);
                    border-radius: 17px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #ffffff;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
</style>